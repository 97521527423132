<template>
  <div>
    <van-cell>
      <template slot="title">
        <span style="color: red"
          >*温馨提示：请先将当前绑定的主卡升级为电子健康卡再执行其它操作。</span
        >
      </template>
    </van-cell>
    <van-list>
      <van-cell-group style="margin-top: 10px">
        <van-cell title="姓名">
          <template #default>
            <span
              style="margin: auto 6px"
              :style="!list.userName ? { color: 'red' } : { color: '' }"
              >{{ list.userName ? list.userName : "未填写" }}</span
            >
          </template>
          <template #right-icon>
            <van-tag
              color="#57c4b7"
              @click="modify('userName', list.userName, '姓名')"
              >修改</van-tag
            >
          </template>
        </van-cell>
        <van-cell title="身份证号">
          <template #default>
            <span
              style="margin: auto 6px"
              :style="!list.idCard ? { color: 'red' } : { color: '' }"
              >{{ list.idCard ? list.idCard : "未填写" }}</span
            >
          </template>
          <template #right-icon>
            <van-tag
              color="#57c4b7"
              @click="modify('idCard', list.idCard, '身份证号')"
              >修改</van-tag
            >
          </template>
        </van-cell>

        <van-cell title="民族">
          <template #default>
            <span
              style="margin: auto 6px"
              :style="!nation ? { color: 'red' } : { color: '' }"
              >{{ nation ? nation : "未填写" }}</span
            >
          </template>
          <template #right-icon>
            <van-tag color="#57c4b7" @click="modify('nation', nation, '民族')"
              >修改</van-tag
            >
          </template>
        </van-cell>
        <van-cell title="性别">
          <template #default>
            <span
              style="margin: auto 6px"
              :style="!Sex ? { color: 'red' } : { color: '' }"
              >{{ Sex ? Sex : "未填写" }}</span
            >
          </template>
          <template #right-icon>
            <van-tag color="#57c4b7" @click="modify('Sex', Sex, '性别')"
              >修改</van-tag
            >
          </template>
        </van-cell>
        <van-cell title="手机号码">
          <template #default>
            <span
              style="margin: auto 6px"
              :style="!list.phone ? { color: 'red' } : { color: '' }"
              >{{ list.phone ? list.phone : "未填写" }}</span
            >
          </template>
          <template #right-icon>
            <van-tag
              color="#57c4b7"
              @click="modify('phone', list.phone, '手机号码')"
              >修改</van-tag
            >
          </template>
        </van-cell>

        <van-cell title="出生日期">
          <template #default>
            <span
              style="margin: auto 6px"
              :style="!Birthday ? { color: 'red' } : { color: '' }"
              >{{ Birthday ? Birthday : "未填写" }}</span
            >
          </template>
          <template #right-icon>
            <van-tag
              color="#57c4b7"
              @click="modify('Birthday', Birthday, '出生日期')"
              >修改</van-tag
            >
          </template>
        </van-cell>
        <van-cell title="居住地址">
          <template #default>
            <span
              style="margin: auto 6px"
              :style="!familyAddress ? { color: 'red' } : { color: '' }"
              >{{ familyAddress ? familyAddress : "未填写" }}</span
            >
          </template>
          <template #right-icon>
            <van-tag color="#57c4b7" @click="modify('familyAddress', familyAddress, '地址')"
              >修改</van-tag
            >
          </template>
        </van-cell>
        <!-- <van-cell style="padding:0 30px;">
             <span style="float:right;color:blue;font-size:14px;text-decoration:underline;
             padding: 10px 0px;" @click="getTextCode">已有健康卡，前往一键关联</span>
        </van-cell> -->
      </van-cell-group>
      <van-button
        style="display: block; margin: 15px auto"
        color="#57c4b7"
        type="primary"
        @click="input"
        >确认提交</van-button
      >
       <!-- <van-button
        style="display: block; margin: 15px auto"
        color="#57c4b7"
        type="primary"
        @click="test1"
        >cs</van-button
      > -->
      
    </van-list>
    <van-dialog
      v-model="showDialog"
      :title="'修改' + upData.title"
      show-cancel-button
      @confirm="onConfirm"
    >
      <van-cell-group>
        <van-field
          v-model="upData.value"
          :label="upData.title"
          :placeholder="'请输入' + upData.title"
        />
      </van-cell-group>
    </van-dialog>
    <van-overlay :show="showjoin" style="text-align: center; line-height: 500px"
      ><span style="color: #57c4b7; font-size: 18px">加载中...</span>
    </van-overlay>
    <div
      style="
        font-size: 12px;
        color: #d3d3d3;
        margin: 1% 40%;
        width: auto;
        height: 40px;
        text-align: center;
      "
    >
      <span>&copy;拓实银医</span>
    </div>
    <!-- 开局遮罩层 -->
    <van-popup v-model="show" :close-on-click-overlay="false">
      <div style="background-color: rgba(0, 0, 0, 0.7)">
        <van-loading size="24px" vertical color="#0094ff"
          >加载中...</van-loading
        >
      </div>
    </van-popup>
  </div>
</template>

<script>
import "@/assets/iconfont/write/iconfont.css";
import ajax from "../../lib/ajax";
// import store from "../../store";
// const hospitalld="34743";
// import { Dialog } from 'vant';
import { Toast } from "vant";
export default {
  data() {
    return {
      show: false,
      nation: "", //民族
      qrCodeText: "",
      wechatCode: "", //微信授权码
      openid: "",
      userId: 0,
      showDialog: false,
      Sex: "",
      Birthday: "",
      familyAddress: "",
      list: [],
      card: {}, //健康卡
      upData: "", //更新信息
      healthCardId: "", //健康卡id
      showjoin: false,
    };
  },
  created() {
    let wechatCode = this.$route.query.wechatCode;
    // let wechatCode = `5F191C412F224FB18898025D43CF0F8F`;
    // let redirect_uri = "https://wx.lcxdyrmyy.cn";
    let redirect_uri="http://wx.fgxrmyy.cn/upOwner";
    if (!wechatCode) {
      window.location.href = `https://health.tengmed.com/open/getUserCode?apiVersion=2&redirect_uri=${redirect_uri}`;
    }
    this.wechatCode = wechatCode;
    let openid = sessionStorage.getItem("openid");
    this.openid = openid;
    this.onload();
  },
  methods: {
    // 测试健康卡使用
    test1(){
       ajax
              .get(
                `/Api/CardService/UpdateBarCode?input=530125200201171512&newBarCode=&openid=${this.openid}`
              )
              .then((res) => {
                if (res.data.result > 0) {
                  Toast.success("绑定成功");
                  // this.$router.replace({ path: "/healcard" }).catch((err) => {
                  //   console.log(err);
                    this.showjoin = false;
                  // });
                }
              })
              .catch(() => {
                // this.show=false;
                this.showjoin = false;
                Toast.fail("服务器错误");
              });
    },
    onload() {
      // let lists = [];
      //获取用户信息
      //  let idCard = store.getters["store/idcard"];
      ajax
        .post("/Api/LoginService/LoginByWeChat?input=" + this.openid)
        .then((res) => {
          this.list = res.data.result;
          let c = this.list.idCard;
          let year = c.substring(6, 10);
          let month = c.substring(10, 12);
          let day = c.substring(12, 14);
          let sex = c.substring(16, 17);
          this.Sex = sex % 2 > 0 ? "男" : "女";
          this.Birthday = year + "-" + month + "-" + day;
          this.familyAddress = this.list.familyAddress 
        })
        .catch(() => {
          Toast.fail("获取成员错误，openid" + this.openid);
        });
    },
    //注册
    input() {
      this.showjoin = true;
      let phoneReg = /1[3-9]\d{9}/;
      let idCardReg =
        /^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
      let BirthdayReg = /[0-9]{4}-[0-9]{2}-[0-9]{2}/;
      if (!this.list.userName) {
        Toast.fail("姓名未填写");
        this.showjoin = false;
        return;
      } else if (!this.list.idCard) {
        Toast.fail("身份证号未填写");
        this.showjoin = false;
        return;
      } else if (!idCardReg.test(this.list.idCard)) {
        Toast.fail("身份证号格式错误");
        this.showjoin = false;
        return;
      } else if (!this.nation) {
        Toast.fail("民族未填写");
        this.showjoin = false;
        return;
      } else if (!this.Sex) {
        Toast.fail("性别填写未填写");
        this.showjoin = false;
        return;
      } else if (!this.list.phone) {
        Toast.fail("电话未填写");
        this.showjoin = false;
        return;
      } else if (!phoneReg.test(this.list.phone)) {
        this.showjoin = false;
        Toast.fail("电话格式错误");
        return;
      } else if (!this.Birthday) {
        Toast.fail("出生日期未填写");
        this.showjoin = false;
        return;
      } else if (!BirthdayReg.test(this.Birthday)) {
        Toast.fail("出生日期格式错误：xxxx-xx-xx");
        this.showjoin = false;
        return;
      }else if (!this.list.familyAddress) {
        Toast.fail("家庭地址未填写");
        this.showjoin = false;
        return;
      }
      let userName = this.list.userName;
      let phone = this.list.phone;
      let c = this.list.idCard;

      let subData = {
        birthday: this.Birthday,
        idType: "01",
        gender: this.Sex,
        idNumber: c,
        name: userName,
        nation: this.nation,
        phone1: phone,
        wechatCode: this.wechatCode,
      };

      ajax
        .post("/Api/HealthCardService/RegisterHealthCard", subData)
        .then((res) => {
          this.healthCardId = res.data.result.rsp.healthCardId;
          this.qrCodeText = res.data.result.rsp.qrCodeText;
          if (res.data.result.commonOut.errMsg == "成功") {
            //  this.show=true;
            this.bind();
          } else {
            Toast.fail(res.data.result.commonOut.errMsg);
            this.showjoin = false;
          }
        })
        .catch((err) => {
          console.log(err);
          Toast.fail("注册失败，请检查wechatCode");
          this.showjoin = false;
        });
    },
    // //获取二维码
    // getTextCode(){
    //   let redirect_uri="https://wx.lcxdyrmyy.cn/HcCode";
    //  window.location.href=`https://health.tengmed.com/open/getHealthCardList?redirect_uri=${redirect_uri}&hospitalId=${hospitalld}`;
    // },
    //修改按钮
    modify(type, value, title) {
      this.upData = { type, value, title };
      this.showDialog = true;
    },

    //确认修改
    onConfirm() {
      if (this.upData.type == "userName") {
        this.list.userName = this.upData.value;
        this.add();
      } else if (this.upData.type == "phone") {
        this.list.phone = this.upData.value;
        this.add();
      } else if (this.upData.type == "idCard") {
        this.list.idCard = this.upData.value;
        let c = this.list.idCard;
        let year = c.substring(6, 10);
        let month = c.substring(10, 12);
        let day = c.substring(12, 14);
        let sex = c.substring(16, 17);
        this.Sex = sex % 2 > 0 ? true : false;
        this.Birthday = year + "-" + month + "-" + day;
        this.add();
      } else if (this.upData.type == "Sex") {
        this.Sex = this.upData.value;
        this.add();
      } else if (this.upData.type == "Birthday") {
        this.Birthday = this.upData.value;
        this.add();
      } else if (this.upData.type == "nation") {
        this.nation = this.upData.value;
        this.add();
      } else if (this.upData.type == "familyAddress") {
        this.familyAddress = this.upData.value;
        this.add();
      }
    },
    add() {
      let sex = this.Sex;
      let a = {
        uid: this.list.uid,
        idCard: this.list.idCard,
        name: this.list.userName,
        sex: sex == "男" ? true : false,
        address:this.familyAddress,
        phone: this.list.phone,
        machineName: "wechat",
      };
      ajax
        .post("/Api/CardService/UpdateHisUser", a)
        .then((res) => {
          console.log(res);
        })
        .catch((res) => {
          console.log(res);
          Toast.success("修改失败");
          this.showjoin = false;
          
        });
    },

    bind() {
      // ajax.get(`/Api/HealthCardService/BindCardRelation?Patid=530125200201171512&QrCodeText=189DA34C1BC5E55A2585DA0867AD17C6477F51BBC45A5940F75DEE9C83474A23:0:D660E98498E03EB4C4A4FDFAAD1EBF92:5300A0069FWHX0002&AppToken=F44AF8513DEAB893A6447ECE90299CB5`)
      ajax
        .get(
          `/Api/HealthCardService/BindCardRelation?QrCodeText=${this.qrCodeText}&Patid=${this.list.idCard}`
        )
        .then((res) => {
          if (res.data.result.commonOut.errMsg == "成功") {
            //  ajax.get(`/Api/CardService/UpdateCardNo?input=530125200201171512&newCardNo=189DA34C1BC5E55A2585DA0867AD17C6477F51BBC45A5940F75DEE9C83474A23`)
            ajax
              .get(
                `/Api/CardService/UpdateBarCode?input=${this.list.idCard}&newBarCode=${this.healthCardId}&openid=${this.openid}`
              )
              .then((res) => {
                if (res.data.result > 0) {
                  Toast.success("绑定成功");
                  this.$router.replace({ path: "/healcard" }).catch((err) => {
                    console.log(err);
                    this.showjoin = false;
                  });
                }
              })
              .catch(() => {
                // this.show=false;
                this.showjoin = false;
                Toast.fail("服务器错误");
              });
          } else {
            Toast.fail("绑定失败,请核对身份信息是否正确");
            this.showjoin = false;
          }
        })
        .catch(() => {
          //  this.show=false;
          Toast.fail("参数错误");
          this.showjoin = false;
        });
    },
  },
};
</script>

<style>
</style>
